import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { Paragraph, Heading5 } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DownloadBrushes } from '~/gatsby-theme-docz/components/DownloadBrushes';
import farge1 from './Grafiskeelementer/Typo-steder-korall-kaviar.png';
import farge2 from './Grafiskeelementer/Typo-steder-korall.png';
import farge3 from './Grafiskeelementer/Typo-steder.png';
import farge4 from './Grafiskeelementer/Typo-steder-blue.png';
import linjeavstand from './Grafiskeelementer/Typo-linjeavstand.png';
import strek1 from './Grafiskeelementer/EN_strek_typer.png';
import strek2 from './Grafiskeelementer/EN_strek_patterns.png';
import strek3 from './Grafiskeelementer/EN_strek_linjeavstand.png';
import strek4 from './Grafiskeelementer/EN_strek_forhold.png';
import strek5 from './Grafiskeelementer/EN_strek_plassering.png';
import strek6 from './Grafiskeelementer/EN_strek_str.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <h2 {...{
      "id": "typografi"
    }}>{`Typografi`}</h2>
    <p>{`Typografi er et av de bærende grafiske elementene i identiteten. Gjennom bruk av ulike vekter gjør vi innholdet til helten og skaper et lekent og variert uttrykk. Oppsettet tilfører bevegelse og dynamikk til identiteten, og understøtter mangfoldigheten og kontrastene i alle de forskjellige turene Entur tilrettelegger for.`}</p>
    <h3 {...{
      "id": "fargekombinasjoner"
    }}>{`Fargekombinasjoner`}</h3>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={farge1} width="100%" alt="" />
    <Paragraph mdxType="Paragraph">Korall og lys korall på blå bakgrunn.</Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={farge2} width="100%" alt="" />
    <Paragraph mdxType="Paragraph">Korall på lys bakgrunn.</Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={farge3} width="100%" alt="" />
    <Paragraph mdxType="Paragraph">Farget på lys bakgrunn.</Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={farge4} width="100%" alt="" />
    <Paragraph mdxType="Paragraph">Farget på blå bakgrunn.</Paragraph>
  </GridItem>
    </GridContainer>
    <h3 {...{
      "id": "linjeavstand"
    }}>{`Linjeavstand`}</h3>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={8} mdxType="GridItem">
    <img src={linjeavstand} width="100%" alt="" />
  </GridItem>
  <GridItem small={12} medium={4} mdxType="GridItem">
    <Paragraph mdxType="Paragraph">Mellom ord skal avstanden bestå av mellomrom x 2.</Paragraph>
    <Paragraph mdxType="Paragraph">
      Mellom linjene skal avstanden være 1.1 x punkt størrelsen.
    </Paragraph>
  </GridItem>
    </GridContainer>
    <h2 {...{
      "id": "strek-og-mønster"
    }}>{`Strek og mønster`}</h2>
    <img src={strek1} width="100%" alt="Illustrerer de forskjellige strekstilene til Entur" />
    <p>{`Den røde streken er hentet fra logoen, og danner grunnlaget for strekstilen i Entur.
De forskjellige strekene symboliserer de ulike transportmidlene.
Strekene brukes for seg, i tillegg til sammen med stedsnavn/ulike typer transportmidler.`}</p>
    <p><strong parentName="p">{`NB! I markedsmateriell, skal strekene kun brukes i korall.`}</strong></p>
    <p>{`Strekene er lagret som brushes i en .ai fil og kan lastes ned her:`}</p>
    <DownloadBrushes mdxType="DownloadBrushes" />
    <img src={strek2} width="100%" alt="Illustrerer strekstilen i bruk med stedsnavn, eller ved å kombinere strekstiler" />
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={strek3} width="100%" alt="" />
    <Heading5 mdxType="Heading5">Linjeavstand</Heading5>
    <Paragraph mdxType="Paragraph">
      Avstanden mellom linjene er 2,5 ganger høyden av stedsnavnet.
    </Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={strek4} width="100%" alt="" />
    <Heading5 mdxType="Heading5">Linjeavstand</Heading5>
    <Paragraph mdxType="Paragraph">
      20 px avstand mellom linjene i 100 px bredde. 4 enheter pr. 100 px bredde.
    </Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={strek5} width="100%" alt="" />
    <Heading5 mdxType="Heading5">Plassering</Heading5>
    <Paragraph mdxType="Paragraph">
      I kombinasjon med linjer skal stedsnavn ikke stå over hverandre. Dette for
      å skape balanse i mønsteret og for å unngå at linjeavstanden ser
      ubalansert ut.
    </Paragraph>
  </GridItem>
  <GridItem small={12} medium={6} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={strek6} width="100%" alt="" />
    <Heading5 mdxType="Heading5">Strektykkelse</Heading5>
    <Paragraph mdxType="Paragraph">
      Strekenes tykkelse samsarer med linjetykkelsen til vertikalene i Nationale
      Medium.
    </Paragraph>
  </GridItem>
    </GridContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      